import { ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollAccordionService {
  constructor(
    private readonly viewportScroller: ViewportScroller,
    private readonly document: Document,
    private readonly window: Window,
  ) {}

  scrollToId(id: string, distanceToTop = 75, alwaysScroll = false): void {
    const elementById = this.document.getElementById(id);
    if (elementById && (elementById.getBoundingClientRect().top < 55 || alwaysScroll)) {
      this.viewportScroller.scrollToPosition([
        0,
        elementById.getBoundingClientRect().top + this.window.scrollY - distanceToTop,
      ]);
    }
  }
}
